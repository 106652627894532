type Flag = {
    emails: string[]
}

type FeatureFlag = Record<string, Flag>

interface Config {
    grafana: {
        appName: string
        env: string
        collectorUrl: string
    }
    auth: {
        smsOtp: string
    }
    endpoints: {
        auth: string
        api: string
        uranus: string
        dionysus: string
        backOffice: string
        hydra: string
    }
    featureFlags?: FeatureFlag
}

const staging: Config = {
    grafana: {
        appName: 'turbocharger',
        env: 'staging',
        collectorUrl:
            'https://faro-collector-prod-eu-west-2.grafana.net/collect/bd1755ded0f5767240484c9a1ca2d12b'
    },
    auth: {
        smsOtp: '123456'
    },
    endpoints: {
        auth: 'https://auth.staging.pleo.io',
        api: 'https://api.staging.pleo.io',
        uranus: 'https://uranus.staging.pleo.io',
        dionysus: 'https://dionysus.staging.pleo.io/',
        backOffice: 'https://backoffice.staging.pleo.io',
        hydra: 'https://api.staging.pleo.io/rest/v1/billing/'
    }
}

const production: Config = {
    grafana: {
        appName: 'turbocharger',
        env: 'production',
        collectorUrl:
            'https://faro-collector-prod-eu-west-2.grafana.net/collect/bd1755ded0f5767240484c9a1ca2d12b'
    },
    auth: {
        smsOtp: '123456'
    },
    endpoints: {
        auth: 'https://auth.pleo.io',
        api: 'https://api.pleo.io',
        uranus: 'https://uranus.pleo.io',
        // dionysus is only deployed in staging
        dionysus: 'https://dionysus.staging.pleo.io/',
        backOffice: 'https://backoffice.staging.pleo.io',
        hydra: 'https://api.pleo.io/rest/v1/billing/'
    },
    featureFlags: {}
}

const host = window.location.host

export const isStaging = host.includes('staging') || host.includes('dev')

const config = isStaging ? staging : production

export default config
